'use client';
import {
  Backpack,
  Building05,
  Plane,
  Ticket01,
  typographyVariants,
  UserCheck02,
  UsersPlus,
} from '@v2/ui';
import { cn } from '@v2/utils';
import { useState } from 'react';

interface SectionData {
  sectionType: string;
  text: string;
  originalValue: any;
  headerIndex: number;
}

const parseSectionText = ({
  sectionType,
  text,
  headerIndex,
  originalValue,
}: SectionData) => {
  const headerIcons = [
    <Backpack size="24" key="backpack" />,
    <UserCheck02 size="24" key="user-check-02" />,
    <UsersPlus size="24" key="users-plus" />,
    <Ticket01 size="24" key="ticket-01" />,
    <Building05 size="24" key="building-05" className="text-[#1D2939]" />,
    <Plane size="24" key="plane" />,
  ];
  const textTypes = {
    ['title']: (content: string) => (
      <h1 className="mb-5 text-lg font-bold text-gray-800 md:mb-8 md:text-2xl">
        About {content}
      </h1>
    ),
    ['header']: (content: string) => (
      <div className="mb-3 mt-8 flex flex-row items-center gap-4">
        {headerIcons[headerIndex] || <Backpack key="backpack" />}
        <h2 className="text-base font-bold text-gray-800 md:text-xl">
          {content}
        </h2>
      </div>
    ),
    ['p']: (content: string) => (
      <p className="ml-10 text-sm font-medium text-gray-500 md:text-base">
        {content}
      </p>
    ),

    ['bullets']: (content: string[]) => (
      <ul className="my-2 ml-14 list-disc flex-col gap-y-0.5">
        {originalValue.map((text) => (
          <li
            className={cn(
              typographyVariants({ size: 'tmd' }),
              'font-normal text-gray-500'
            )}
            key={text}
          >
            {text}
          </li>
        ))}
      </ul>
    ),
  };
  return textTypes[sectionType](text);
};

export const TeamDescription = ({ teamDescription }) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  const toggleSection = () => {
    setIsSectionExpanded((oldValue) => !oldValue);
  };
  let headerIndex = -1;
  return (
    <div className="pr-4 md:pr-0">
      <div
        className={`w-full ${isSectionExpanded ? '' : 'max-h-80 overflow-hidden'} duration-1000 ease-in`}
      >
        {teamDescription.map((section, index) => {
          const [sectionType, text] = Object.entries(section)[0];
          if (sectionType === 'header') {
            headerIndex++;
          }
          return (
            <div key={`${sectionType}_${index}`}>
              {parseSectionText({
                sectionType,
                text: String(text),
                originalValue: text,
                headerIndex,
              })}
            </div>
          );
        })}
      </div>
      <div
        className={`${isSectionExpanded ? 'hidden' : 'relative'} z-10 mt-[-100px] h-28 w-full bg-gradient-to-b from-white/20 to-white`}
      ></div>

      <button
        onClick={toggleSection}
        className="mt-6 w-full rounded-lg border border-gray-200 bg-[#F7F8FF] py-4 font-semibold text-gray-800"
      >
        {isSectionExpanded ? 'Show less' : 'Show more'}
      </button>
    </div>
  );
};
